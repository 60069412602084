<!-- 我的卡券-->
<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="0">
                    <van-empty v-if="couponList.length < 1" :image="require('@/assets/images/user/user_card_empty.png')" description="暂无可用优惠券" style="transform: translateY(50%)"></van-empty>
                    <div v-else style="padding: 20px 10px;">
                        <!-- <user-card v-for="item, index in couponList" :key="index" :card="item" @click-info="showInfoDialog"></user-card> -->
                        <coupon-info v-for="item, index in couponList" :key="index" :couponInfo="item" :pick="true" @on-click="onPick"></coupon-info>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
                <van-button @click="showAdd = true" round>
                    <template #icon>
                        <van-icon name="add-o" :color="$config.style.primaryColor"></van-icon>
                    </template>
                    <span>添加优惠券</span>
                </van-button>
                <van-button type="primary" @click="beforeUseCoupon" round :disabled="disabledBtn">
                    <span>确 认</span>
                </van-button>
            </div>
        <div class="utils-wrap">
            <user-card-dialog v-model="showAdd" @confirm="confirmAdd"></user-card-dialog>
        </div>
    </div>
</template>

<script>
import UserCard from '@/components/user/user-card';
import CouponInfo from '@/components/user/user-coupon-info';
import UserCardDialog from '@/components/user/user-card-dialog';
import { mapGetters } from 'vuex'
import { queryCouponByOrder, useCoupon, cancelUseCoupon } from '@api/coupon-request';
import pagination from '@/components/mixins/pagination';
import { findIndex, filter } from 'lodash'
export default {
    mixins: [pagination],
    data() {
        return {
            orderId: this.$route.query.orderId,
            qty: this.$route.query.qty,
            activeIndex: 0,
            couponList: [],
            exchangeCardList: [],
            showAdd: false,
            cardInfo: null,
            cardName: null,
        }
    },
    computed: {
        ...mapGetters([
            'appKey'
        ]),
        disabledBtn() {
            if (this.couponList.length < 1) return true;
            return false;
        },
        selected() {
            return filter(this.couponList, {isUse: true});
        },
    },
    methods: {
        beforeUseCoupon() {
            if (this.selected.length < 1) {
                return this.cancelUseCoupon();
            }

            if (this.selected.length <= this.qty) {
                return this.useCoupon();
            }

            this.$toast('优惠券数量不可超过座位数');
        },
        useCoupon() {

            let params = {
                orderId: this.orderId,
                couponNo: this.selected.map(item => item.couponNo).join(',')
            }
            useCoupon(params).then((res) => {
                this.$goPage(-1);
            });
        },
        cancelUseCoupon() {
            let params = {
                orderId: this.orderId,
            }
            cancelUseCoupon(params).then((res) => {
                this.$goPage(-1);
            })
        },
        onPick(couponInfo) {
            let index = findIndex(this.couponList, { id: couponInfo.id });
            let isUse = couponInfo.isUse;
            // this.couponList = this.couponList.map((item) => { return { ...item, isUse: false } });
            this.$set(this.couponList, index, { ...couponInfo, isUse: !!!isUse });
        },
        getACouponList() {
            queryCouponByOrder({orderId: this.$route.query.orderId}).then((res) => {
                console.log(res);
                this.couponList = res;
            });
            // return new Promise((resolve) => {
            //     let params = {
            //         pageNo: this.pagination.pageNo,
            //         pageSize: 20,
            //     }
            //     getCouponList(params).then((res) => {
            //         this.copyPagination(res);
            //         this.couponList = this.couponList.concat(res.records);
            //         this.couponList.sort((last) => last.status == 'L' ? 1 : -1);
            //         resolve();
            //     });
            // })
        },
        loadMore() {
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getACouponList();
            }
        },
        confirmAdd(val) {
            this.$goPage('user-coupon-bind', {
                couponPass: val
            })
        },
        initPage(type) {
            this.pagination = this.$config.getDefaultPagination();
            this.couponList = [];
            this.exchangeCardList = [];
            let loading = this.$toast.loading(this.$config.loadingMsg);
            Promise.all([this.getACouponList()]).then((res) => {
                loading.clear();
            });
        }
    },
    mounted() {
        this.initPage();
    },
    components: {
        CouponInfo,
        UserCardDialog,
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	height: 0px;
	overflow: hidden;
	/deep/ .van-tabs__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		.van-tab__pane {
			flex: 1;
			position: relative;
			.movie_tab-wrap {
				padding: 0 7px;
			}
		}
	}
}
.footer-wrap {
	padding: 20px;
	width: 100%;
	background-color: #fff;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
}
</style>
